
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Appear = makeShortcode("Appear");
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Design Projects`}</h1>
    <p>{`(2019-2021)`}</p>
    <p>{`Ram Bharathi`}</p>
    <hr></hr>
    <h1>{`My Role`}</h1>
    <p>{`Sr. Product Designer`}</p>
    <p>{`...`}</p>
    <ul>
  <li>Create products and experiences that users love and value</li>
  <Appear mdxType="Appear">
    <li>Create IA, wireframe, user flow & customer journey</li>
    <li>Prototype early & stage content hierarchies</li>
    <li>Screen design & interactions / dev spec hand-off </li>
    <li>Ritualize product design process & culture</li>
    <li>Involve UX researchers when needed</li>
    <li>Build Design System for digital products</li>
  </Appear>
    </ul>
    <p>{`...`}</p>
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch1-1-my-role-intro.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch1-2-design-tools-zeplin.jpg" mdxType="Image" />
    <hr></hr>
    <h1>{`Design Process`}</h1>
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch1-3-role-design-process.gif" mdxType="Image" />
    <hr></hr>
    <h1>{`Video`}</h1>
    <p><a parentName="p" {...{
        "href": "https://youtu.be/99VbNQ08ovc"
      }}>{`Squad - Product Team`}</a></p>
    <hr></hr>
    <h4>{`Project #1`}</h4>
    <h1>{`Building a Referral System`}</h1>
    <p>{`Improve user conversion`}</p>
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch2-1-timeline.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch2-2-customer-journey.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch2-3-customer-journey.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch2-4-user-flow1.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch2-4-user-flow2.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch2-4-user-flow3.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="https://rambharathi.com/images/IA-yongo-users.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="https://rambharathi.com/images/mdx-deck/ch2-5-whiteboard.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="https://rambharathi.com/images/mdx-deck/ch2-6-sketching.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="https://rambharathi.com/images/mdx-deck/ch2-7-sketching.jpg" mdxType="Image" />
    <hr></hr>
    <h4>{`Referral Program`}</h4>
    <h1>{`Design Phase`}</h1>
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch3-1-design-content.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch3-2-design-content.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch3-3-design-content.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/ch3-4-design-content.jpg" mdxType="Image" />
    <hr></hr>
    <h1>{`Screen design`}</h1>
    <h4>{`Dev spec hand-off`}</h4>
    <hr></hr>
    <Image src="https://rambharathi.com/images/m2m-01.jpg" mdxType="Image" />
    <hr></hr>
    <p>{`Help component (`}<a parentName="p" {...{
        "href": "https://portal.yongo.be/en/ReferMember/ceilgr"
      }}>{`https://portal.yongo.be/en/ReferMember/ceilgr`}</a>{`)`}</p>
    <Image src="https://rambharathi.com/images/m2m-hiw-component.jpg" mdxType="Image" />
    <hr></hr>
    <h4>{`Project #2`}</h4>
    <h1>{`Design System`}</h1>
    <p>{`UI component library using Sketch, Zeplin, Storybookjs & VS code`}</p>
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/dd2-meeting1.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/dd2-meeting2.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="http://rambharathi.com/images/mdx-deck/dd2-meeting3.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="https://rambharathi.com/images/yongo-library.png" mdxType="Image" />
    <hr></hr>
    <h2>{`Why`}</h2>
    <p>{`...`}</p>
    <ul>
      <li parentName="ul">{`Expanding digital products v UI consistency`}</li>
      <li parentName="ul">{`Design efficiency with modern web apps`}</li>
      <li parentName="ul">{`Source of truth`}</li>
      <li parentName="ul">{`One repository for design, dev & product team.`}</li>
    </ul>
    <p>{`...`}</p>
    <hr></hr>
    <h4>{`# 3`}</h4>
    <h1>{`Other projects`}</h1>
    <hr></hr>
    <h1>{`Online shopping in Yongo`}</h1>
    <h4>{`Buy gifts online (webshop partner integration)`}</h4>
    <hr></hr>
    <h2>{`Why?`}</h2>
    <p>{`...`}</p>
    <ul>
      <li parentName="ul">{`Tapping 'life events celebration'`}</li>
      <li parentName="ul">{`Unblock the 'What gift to buy?' moment`}</li>
      <li parentName="ul">{`Save & Spend learning for kids`}</li>
      <li parentName="ul">{`Partners for Yongo & cross selling`}</li>
      <li parentName="ul">{`Customer base expansion`}</li>
    </ul>
    <p>{`...`}</p>
    <hr></hr>
    <Image src="https://rambharathi.com/images/yongo-fairplace-01.jpg" mdxType="Image" />
    <hr></hr>
    <Image src="https://rambharathi.com/images/yongo-fairplace-02.jpg" mdxType="Image" />
    <hr></hr>
    <h1>{`Motion design`}</h1>
    <h4>{`Lottie Animation Framework`}</h4>
    <hr></hr>
    <h2>{`How?`}</h2>
    <p>{`...`}</p>
    <ul>
      <li parentName="ul">{`Scalable animation framework`}</li>
      <li parentName="ul">{`Design interactive animations`}</li>
      <li parentName="ul">{`Prototype for real experience`}</li>
      <li parentName="ul">{`Framer / Codepen`}</li>
    </ul>
    <p>{`...`}</p>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://lottiefiles.com/ram"
      }}>{`Lottie Animations`}</a>{` | `}<a parentName="p" {...{
        "href": "https://codepen.io/rambharathi/full/wvoOVrZ"
      }}>{`My Codepen`}</a></p>
    <Image src="https://rambharathi.com/images/lottie-ram.jpg" mdxType="Image" />
    <hr></hr>
    <h1>{`Design - Code`}</h1>
    <p>{`Deep interest in ReactJS, Gatsby, HTML/CSS
(Built this deck on)`}</p>
    <hr></hr>
    <Image src="https://rambharathi.com/images/react-gatsby.jpg" mdxType="Image" />
    <hr></hr>
    <h1>{`Thank You`}</h1>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;